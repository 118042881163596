@charset "utf-8";

// Import partials from `sass_dir` (defaults to `_sass`)



@import
  
    "bootswatch/spacelab/variables",
  

  "bootstrap",

  
    "bootswatch/spacelab/bootswatch",
  

  "syntax-highlighting",
  "typeahead"
;

html {
  position: relative;
  min-height: 100%;
}
body {
  padding-top: $navbar-height + $navbar-margin-bottom;
  margin-bottom: 46px;
}

.navbar-brand{
  img{
    margin: -$navbar-padding-vertical 0;
    height: $navbar-height;
    padding: 10px 0;
  }
}


.header-container {
  background-color: black;
  background: url('../img/bg.jpg') no-repeat 50% 0;
  color: #fff;

  h1 {
    color: #fff;
  }
  // background-attachment: fixed;
  background-size: cover;
  background-position: center 36%;
  margin-top: -37px;
}
.navbar-container {
  font-size: 16px;
}
.page-content {
  padding-bottom: 6em;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-top: 1em;
  padding-bottom: 1em;
  background-color: $gray-lighter;
  color: $gray-dark;
}

.post-list-container {
  li a.active {
    font-weight: bold;
  }
}

*[id]:before {
  display: block;
  content: " ";
  margin-top: -75px;
  height: 75px;
  visibility: hidden;
}
.navbar-form .has-feedback .form-control-feedback{
  top:10px
}

/* ----------- side navigation ---------- */

.sidenav li.sidenav-h1 {
	margin-left: 0em;
}
.sidenav li.sidenav-h2 {
	margin-left: 1em;
}
.sidenav li.sidenav-h3 {
	margin-left: 1em;
}

.sidenav li > a {
	padding-top: .3em; // !important;
	padding-bottom: 0px;
}

.sidenav li > a:hover,
.sidenav li > a:focus {
	background: none !important;
	text-decoration: underline;
}

/* ----------- markdown fixes ---------- */

#markdown-content-container table { @extend .table; }
#markdown-content-container img { @extend .img-responsive; }
